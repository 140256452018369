.ce-formplaceholder {
  line-height: 1.6em;
  padding: 1em;
  margin: 0.7em 0;
  border: 1px solid #e5e6ec;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
}

.ce-formplaceholder [data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
}

.ce-formplaceholder [contenteditable='true'] {
  display: inline-block;
  width: 100%;
}

.ce-formplaceholder .cdx-block {
  outline: none;
}

.ce-formplaceholder .ce-title {
  font-size: 1em;
  margin: 0;
  padding-bottom: 0;
}

.ce-formplaceholder .ce-text {
  font-size: 0.9em;
  font-weight: bold;
}
